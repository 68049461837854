import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  placeholder: "blurred",
  height: 32,
  alt: "Brand Icon",
};

export const BRANDS = [
  {
    image: <StaticImage src="../../images/brands/huawei.png" {...imgProps} />,
    percentage: "25.47%",
    text: "Huawei",
  },
  {
    image: <StaticImage src="../../images/brands/apple.png" {...imgProps} />,
    percentage: "21.83%",
    text: "Apple",
  },
  {
    image: <StaticImage src="../../images/brands/oppo.png" {...imgProps} />,
    percentage: "16.87%",
    text: "Oppo",
  },
  {
    image: <StaticImage src="../../images/brands/vivo.png" {...imgProps} />,
    percentage: "16.06%",
    text: "Vivo",
  },
  {
    image: <StaticImage src="../../images/brands/xiaomi.png" {...imgProps} />,
    percentage: "10.93%",
    text: "Xiaomi",
  },
  {
    image: <StaticImage src="../../images/brands/samsung.png" {...imgProps} />,
    percentage: "2.12%",
    text: "Samsung",
  },
  {
    image: <StaticImage src="../../images/brands/oneplus.png" {...imgProps} />,
    percentage: "1.52%",
    text: "One Plus",
  },
  {
    image: <StaticImage src="../../images/brands/meizu.png" {...imgProps} />,
    percentage: "1.35%",
    text: "Meizu",
  },
  {
    image: (
      <StaticImage src="../../images/brands/nubia-logo.png" {...imgProps} />
    ),
    percentage: "0.37%",
    text: "Nubia",
  },
  {
    image: (
      <StaticImage src="../../images/brands/smartisan.jpg" {...imgProps} />
    ),
    percentage: "0.3%",
    text: "Smartisan",
  },
  {
    image: <StaticImage src="../../images/brands/lenovo.png" {...imgProps} />,
    percentage: "0.23%",
    text: "Lenovo",
  },
  {
    image: <StaticImage src="../../images/brands/gionee.png" {...imgProps} />,
    percentage: "0.23%",
    text: "Gionee",
  },
  {
    image: <StaticImage src="../../images/brands/nokia.png" {...imgProps} />,
    percentage: "0.2%",
    text: "Nokia",
  },
  {
    image: <StaticImage src="../../images/brands/zte.png" {...imgProps} />,
    percentage: "0.2%",
    text: "ZTE",
  },
  {
    image: <StaticImage src="../../images/brands/360.png" {...imgProps} />,
    percentage: "0.13%",
    text: "360",
  },
  {
    image: <StaticImage src="../../images/brands/meitu.png" {...imgProps} />,
    percentage: "0.13%",
    text: "Meitu",
  },
  {
    image: <StaticImage src="../../images/brands/hisense.png" {...imgProps} />,
    percentage: "0.1%",
    text: "Hisense",
  },
  {
    image: <StaticImage src="../../images/brands/realme.png" {...imgProps} />,
    percentage: "0.08%",
    text: "Realme",
  },
  {
    image: (
      <StaticImage src="../../images/brands/motorola-logo.png" {...imgProps} />
    ),
    percentage: "0.05%",
    text: "Motorola",
  },
  {
    image: <StaticImage src="../../images/brands/letv.png" {...imgProps} />,
    percentage: "0.04%",
    text: "LeTV",
  },
].map((it, index) => ({ ...it, index: index + 1 }));